import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import { useSiteMetaContext } from '../../context/SiteMetaContext';
import Container from '../../layout/Container';
import Button from '../../components/Button';
import { StaticImage } from 'gatsby-plugin-image';

const SalesContainer = styled(Container)`
  padding-bottom: ${({ theme }) => theme.sizes.lvl16};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  && {
    color: ${({ theme }) => theme.colours.white};
    text-align: center;
    line-height: 1.1;
    max-width: none;
    margin: 0 auto ${({ theme }) => theme.sizes.lvl6} auto;
  }
`;

const DownArrow = styled.div`
  display: block;
  margin: 0 auto;
  width: ${({ theme }) => theme.sizes.lvl3};
  margin: ${({ theme }) => theme.sizes.lvl3} auto 0;

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `}
`;

const LogoWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.sizes.lvl6} auto;
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  color: white;
`;
const WiderButton = styled(Button)`
  a {
    color: white;
  }
  color: white;
  padding: ${({ theme }) => theme.sizes.lvl0} ${({ theme }) => theme.sizes.lvl9};
`;

const BackgroundSection = ({ data, className, children }) => {
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1))`,
    data.desktop.childImageSharp.fluid,
  ];

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={backgroundFluidImageStack}
      backgroundColor={`#040e18`}
    >
      {children}
    </BackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center top;
  background-repeat: repeat-y;
  background-size: cover;
`;

const IntroSection = ({ className }) => {
  const { title } = useSiteMetaContext();
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "sales_intro_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        puzzle: file(relativePath: { eq: "puzzle_colour.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        arrow: file(relativePath: { eq: "arrow_orange.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <StyledBackgroundSection data={data}>
      <SalesContainer className={className}>
        <LogoWrapper>
          <Link to="/">
            <StaticImage
              src="../../images/logo.png"
              alt={`${title} logo`}
              placeholder="tracedSVG"
            />
          </Link>
        </LogoWrapper>
        <Title className="h1">
          The Ultimate Resource For Overcoming Fatigue
        </Title>
        <ButtonLink to="#salesOptions">
          <WiderButton>Buy Now</WiderButton>
        </ButtonLink>

        <DownArrow>
          <Img fluid={data.arrow.childImageSharp.fluid} />
        </DownArrow>
      </SalesContainer>
    </StyledBackgroundSection>
  );
};

export default IntroSection;
