import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { isMobileOnly } from 'react-device-detect';

import { plans } from '../../constants/plans';
import FlashOnlyIcon from '../../images/flash_drive_only_icon.inline.svg';
import OnlineOnlyIcon from '../../images/online_only_icon.inline.svg';
import Container from '../../layout/Container';
import Ticket from '../../components/Ticket';
import IconList, { PaddingSize } from '../../components/IconList';
import TabButtons from '../../components/TabButtons';
import { useUserContext } from '../../context/UserContext';
import { useSiteMetaContext } from '../../context/SiteMetaContext';

const SalesOptionsContainer = styled(Container)`
  color: white;
  text-align: center;
  padding-top: ${({ theme }) => theme.sizes.lvl2};
`;
const SalesOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-direction: row;
  `};
`;

const SaleOptionWrapper = styled.div<{ $selected: boolean }>`
  flex: 1;
  padding: 0 0 ${({ theme }) => theme.sizes.lvl2} 0;
  :last-child {
    padding-bottom: 0;
  }
  display: ${({ $selected }) => ($selected ? 'block' : 'none')};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: block;
    padding: 0 ${({ theme }) => theme.sizes.lvl2};
    :last-child {
      padding-right: 0;
    }
    :first-child {
      padding-left: 0;
    }
  `}
`;

const Title = styled.h2`
  && {
    margin-bottom: ${({ theme }) => theme.sizes.lvl8};
    font-weight: 400;
  }
`;

const OrangeSpan = styled.span`
  color: ${({ theme }) => theme.colours.primary};
`;
const BlueSpan = styled.span`
  color: ${({ theme }) => theme.colours.secondary};
`;

const IconContainer = styled.span`
  width: ${({ theme }) => theme.sizes.lvl2};
`;

const PositionedTabButtons = styled(TabButtons)`
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `}
`;

const StyledIconList = styled(IconList)`
  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    &:last-child {
      border-bottom: none;
    }
  }
`;

const alwaysIncludedContent = [
  {
    content: '60+ hours of life-changing content',
    materialIcon: 'check_circle',
    included: true,
  },
  {
    content: '45+ feature interviews',
    materialIcon: 'check_circle',
    included: true,
  },
  {
    content: 'In-depth case studies',
    materialIcon: 'check_circle',
    included: true,
  },
  {
    content: 'Yoga & Meditation sessions',
    materialIcon: 'self_improvement',
    included: true,
  },
  {
    content: 'Audio files to listen to on the go',
    materialIcon: 'headphones',
    included: true,
  },
  {
    content: 'Digital transcripts to read',
    materialIcon: 'speaker_notes',
    included: true,
  },
  {
    content: '100% Money Back Guarantee',
    materialIcon: 'check_circle',
    included: true,
  },
];

interface SalesOptionsSection {
  className?: string;
}

const SalesOptionsSection = ({ className }: SalesOptionsSection) => {
  const { isPaid, hasPaidForProduct } = useUserContext();
  const { title } = useSiteMetaContext();
  const [selectedTab, setSelectedTab] = useState<string>('both');
  const basePath = '/checkout';
  const flashPlan = plans.filter((el) => el.id === 'flash')[0];
  const onlineflashPlan = plans.filter((el) => el.id === 'onlineflash')[0];
  const onlinePlan = plans.filter((el) => el.id === 'online')[0];

  return (
    <SalesOptionsContainer className={className}>
      <Title className="h2" id="salesOptions">
        Get <u>lifetime</u> access to the <br />
        <strong>{title}</strong> today!
      </Title>
      <PositionedTabButtons
        value={selectedTab}
        onClick={setSelectedTab}
        tabs={[
          {
            id: 'onlineonly',
            label: (
              <span>
                <strong>Online Access</strong>
                <br />
                Only
              </span>
            ),
          },
          {
            id: 'flashonly',
            label: (
              <span>
                <strong>Flash Drive</strong>
                <br />
                Only
              </span>
            ),
          },
          {
            id: 'both',
            label: (
              <span>
                <strong>Both</strong>
              </span>
            ),
          },
        ]}
      />
      <SalesOptions>
        <SaleOptionWrapper $selected={selectedTab === 'onlineonly'}>
          <Ticket
            title={
              <strong>
                <IconContainer>
                  <OnlineOnlyIcon />
                </IconContainer>
                <div>
                  <BlueSpan>Online Access</BlueSpan> Only
                </div>
              </strong>
            }
            content={
              <StyledIconList
                showMoreAfterXItems={isMobileOnly ? 6 : undefined}
                paddingSize={PaddingSize.LG}
                items={[
                  {
                    content: `Access to ${title} via our online portal`,
                    materialIcon: 'check_circle',
                    included: true,
                  },
                  {
                    content:
                      'Flash Drive with sessions shipped to your address',
                    included: false,
                  },
                  ...alwaysIncludedContent,
                ]}
              />
            }
            paid={isPaid && hasPaidForProduct(onlinePlan.name)}
            oldPrice={`$${onlinePlan.oldPrice}`}
            newPrice={`$${onlinePlan.price}`}
            onButtonClick={() => {
              navigate(`${basePath}/summary?selectedPlan=${onlinePlan.id}`);
            }}
            soldOut={onlinePlan.soldOut}
          />
        </SaleOptionWrapper>
        <SaleOptionWrapper $selected={selectedTab === 'flashonly'}>
          <Ticket
            oldPrice={`$${flashPlan.oldPrice}`}
            newPrice={`$${flashPlan.price}`}
            onButtonClick={() => {
              navigate(`${basePath}/summary?selectedPlan=${flashPlan.id}`);
            }}
            title={
              <strong>
                <IconContainer>
                  <FlashOnlyIcon />
                </IconContainer>
                <div>
                  <OrangeSpan>Flash Drive</OrangeSpan> Only
                </div>
              </strong>
            }
            content={
              <StyledIconList
                showMoreAfterXItems={isMobileOnly ? 6 : undefined}
                paddingSize={PaddingSize.LG}
                items={[
                  {
                    content: `Access to ${title} via our online portal`,
                    included: false,
                  },
                  {
                    content:
                      'Flash Drive with sessions shipped to your address',
                    materialIcon: 'check_circle',
                    included: true,
                  },
                  ...alwaysIncludedContent,
                ]}
              />
            }
            paid={isPaid && hasPaidForProduct(flashPlan.name)}
            soldOut={flashPlan.soldOut}
          />
        </SaleOptionWrapper>
        <SaleOptionWrapper $selected={selectedTab === 'both'}>
          <Ticket
            isBestDeal
            oldPrice={`$${onlineflashPlan.oldPrice}`}
            newPrice={`$${onlineflashPlan.price}`}
            onButtonClick={() => {
              navigate(
                `${basePath}/summary?selectedPlan=${onlineflashPlan.id}`
              );
            }}
            title={
              <strong>
                <IconContainer>
                  <OnlineOnlyIcon />
                  <FlashOnlyIcon />
                </IconContainer>
                <div>
                  <BlueSpan>Online Access</BlueSpan> +{' '}
                  <OrangeSpan>Flash Drive</OrangeSpan>
                </div>
              </strong>
            }
            paid={isPaid && hasPaidForProduct(onlineflashPlan.name)}
            soldOut={onlineflashPlan.soldOut}
            content={
              <StyledIconList
                showMoreAfterXItems={isMobileOnly ? 6 : undefined}
                paddingSize={PaddingSize.LG}
                items={[
                  {
                    content: `Access to ${title} via our online portal`,
                    materialIcon: 'check_circle',
                    included: true,
                  },
                  {
                    content:
                      'Flash Drive with sessions shipped to your address',
                    materialIcon: 'check_circle',
                    included: true,
                  },
                  ...alwaysIncludedContent,
                ]}
              />
            }
          />
        </SaleOptionWrapper>
      </SalesOptions>
    </SalesOptionsContainer>
  );
};

export default SalesOptionsSection;
