import React from 'react';
import styled from 'styled-components';

import Container from '../../layout/Container';

const Background = styled.div`
  background-color: white;
  color: ${({ theme }) => theme.colours.text};
`;
const SalesContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`;

const SalesBenefitContainer = styled.div`
  flex-basis: 100%;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    flex-basis: 50%;
  `}
`;
const SalesBenefitInner = styled.div`
  padding: ${({ theme }) => theme.sizes.lvl1} ${({ theme }) => theme.sizes.lvl3};
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    padding: ${({ theme }) => theme.sizes.lvl6};
    flex-direction: row;
  `}
`;
const Icon = styled.div`
  && {
    font-size: ${({ theme }) => theme.font.sizes.xxxxxl};
    color: ${({ theme }) => theme.colours.secondary};
    margin: 0 0 ${({ theme }) => theme.sizes.lvl1} 0;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      margin: 0 ${({ theme }) => theme.sizes.lvl4} 0 0;
    `}
  }
`;
const Content = styled.div`
  flex: 1;
`;
const Title = styled.h3``;

const SalesBenefit = ({ className, icon, title, children }) => {
  return (
    <SalesBenefitContainer className={className}>
      <SalesBenefitInner>
        <Icon className="material-icons">{icon}</Icon>
        <Content>
          <Title className="h3">{title}</Title>
          <p>{children}</p>
        </Content>
      </SalesBenefitInner>
    </SalesBenefitContainer>
  );
};

const SalesBenefitsSection = ({ className }) => {
  return (
    <Background className={className}>
      <SalesContainer>
        <SalesBenefit icon="people" title="Real-life case studies">
          Follow real people as they go on therapeutic journeys.
        </SalesBenefit>
        <SalesBenefit
          icon="self_improvement"
          title="Yoga &amp; meditation sessions"
        >
          Support your healing with these guided exercises.
        </SalesBenefit>
        <SalesBenefit
          icon="headphones"
          title="Audio files to listen to on the go"
        >
          Continue to learn on the train, in the car, or even whilst exercising!
        </SalesBenefit>
        <SalesBenefit icon="speaker_notes" title="Digital transcripts to read">
          Enjoy all 45+ interviews in written format.
        </SalesBenefit>
      </SalesContainer>
    </Background>
  );
};

export default SalesBenefitsSection;
